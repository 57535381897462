<template>
  <p class="flex gap-1">
    <slot name="beforePrice" />
    <span>{{ formattedPrice }}</span>
    <slot name="afterPrice" />
  </p>
</template>

<script setup lang="ts">
const { getFormattedPrice } = usePrice()
const props = withDefaults(
  defineProps<{
    value: number | undefined
    quantity?: number
  }>(),
  {
    quantity: 1,
  }
)

const totalPrice = computed(() => {
  if (!props.value) {
    return 0
  }
  return props.value * props.quantity
})

const formattedPrice = computed<string>(() =>
  getFormattedPrice(totalPrice.value)
)
</script>
